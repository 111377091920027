export enum PollingEpcActionTypes {
  POLLING_EPC_SUCCESS = 'POLLING_EPC_SUCCESS',
  POLLING_EPC_UNKNOWN = 'POLLING_EPC_UNKNOWN',
  POLLING_EPC_FAIL = 'POLLING_EPC_FAIL',
  POLLING_RETRY = 'POLLING_RETRY',
}

export type PollingEpcSuccessAction = {
  type: PollingEpcActionTypes.POLLING_EPC_SUCCESS;
};

export const pollingEpcSuccess = (): PollingEpcSuccessAction => ({
  type: PollingEpcActionTypes.POLLING_EPC_SUCCESS,
});

export type PollingEpcFailAction = {
  type: PollingEpcActionTypes.POLLING_EPC_FAIL;
};

export const pollingEpcFail = (): PollingEpcFailAction => ({
  type: PollingEpcActionTypes.POLLING_EPC_FAIL,
});

export type PollingEpcUnknownAction = {
  type: PollingEpcActionTypes.POLLING_EPC_UNKNOWN;
};

export const pollingEpcUnknown = (): PollingEpcUnknownAction => ({
  type: PollingEpcActionTypes.POLLING_EPC_UNKNOWN,
});

export type PollingEpcActions =
  | PollingEpcSuccessAction
  | PollingEpcFailAction
  | PollingEpcUnknownAction;
