import React from 'react';
import { Actions } from '../../types';
import { ActionCellWrapper } from '../../TableStyled';
import Action from './Action';

interface ModalTriggerCellProps {
  actions: Actions[];
}

export const ModalTriggerCell: React.FC<ModalTriggerCellProps> = ({
  actions,
}) => (
  <ActionCellWrapper>
    {actions.map((action: Actions) => (
      <Action
        key={`${action.type}_${action.link}`}
        handleAction={action.handleAction}
        link={action.link}
        type={action.type}
        isDisabled={action.isDisabled}
        showTooltipIfDisabled={action.showTooltipIfDisabled}
        modalConfig={action.modalConfig}
      />
    ))}
  </ActionCellWrapper>
);

export default ModalTriggerCell;
