import React from 'react';
import EditableTableCell from './EditableTableCell';
import TableCell from './TableCell';
import LinkTableCell from './LinkTableCell';

import { TableRowComponentProps } from '../types';

const nonRenderColumns = ['id', 'entity', 'originalFloorLevel', 'isPortfolio'];

const TableRow: React.FC<TableRowComponentProps> = ({
  row,
  editInSitu,
  rowId,
  entity,
  useContext,
  vertical,
}: TableRowComponentProps) => {
  const headings = Object.keys(row);

  return (
    <>
      {headings.map((heading: string) => {
        if (!heading || nonRenderColumns.includes(heading)) return null;
        const { isEditable, value, values, link } = row[heading] || {};
        switch (true) {
          case link && !value && !values: {
            return (
              <LinkTableCell
                label={link.label}
                link={link.path}
                key={`${heading}_${rowId}`}
                isExternal={link.isExternal}
              />
            );
          }

          case isEditable && !value && !values: {
            return (
              <EditableTableCell
                heading={heading}
                cell={row[heading]}
                rowId={rowId}
                key={`${heading}_${rowId}`}
                entity={entity}
                editInSitu={editInSitu}
                useContext={useContext}
              />
            );
          }

          default: {
            return (
              <TableCell
                {...row[heading]}
                heading={heading}
                key={`${heading}_${rowId}`}
                vertical={vertical}
              />
            );
          }
        }
      })}
    </>
  );
};

export default TableRow;
