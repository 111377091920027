import React from 'react';
import CRMLink from 'components/CallToActions/CRMLink/CRMLink';
import CRMNearestLink from 'components/CallToActions/CRMNearestLink/CRMNearestLink';
import { IntrohiveRSSPill } from 'components/CallToActions/IntrohivePill/IntrohivePill';
import AnnualRentIcon from 'components/DataValueItem/AnnualRentIcon';
import Tooltip from 'components/Tooltip/Tooltip';
import { Vertical } from 'globalConstants';
import { TableCellType } from '../../types';
import { StyledHeadingCell, Tagline } from '../../TableStyled';
import { getFileIcon, getIcon } from '../../helpers';
import { TABLE_COPY } from '../../constants';

export const HeadingCell: React.FC<TableCellType> = ({
  value,
  icon,
  isBold = false,
  tagline,
  savCrmId,
  savCrmNearestId,
  cellKey,
  isConfirmedTotalRent,
  hasMatchingRecords,
  introhiveScoreId,
  introhiveRelationshipStrengthScore,
  introhiveNearestRelationshipStrengthScore,
}) => {
  const matchingIcon = getIcon(hasMatchingRecords?.indicator);

  const copy =
    hasMatchingRecords?.vertical === Vertical.Lease
      ? TABLE_COPY.tooltip.mergeRecords.lease
      : TABLE_COPY.tooltip.mergeRecords.sale;

  const renderValue = () => {
    if (typeof value === 'boolean') {
      return value ? '✓' : '';
    }

    return value || '-';
  };

  return (
    <StyledHeadingCell shouldWrap={!!tagline} isBold={isBold}>
      {icon && <span>{getFileIcon(icon)}</span>}
      <span>{renderValue()}</span>
      {savCrmId && <CRMLink crmId={savCrmId} />}
      {savCrmNearestId && <CRMNearestLink crmNearestId={savCrmNearestId} />}
      {(introhiveRelationshipStrengthScore ||
        introhiveNearestRelationshipStrengthScore) && (
        <IntrohiveRSSPill
          value={introhiveRelationshipStrengthScore}
          valueNearest={introhiveNearestRelationshipStrengthScore}
          introhiveScoreId={introhiveScoreId}
        />
      )}
      {tagline && <Tagline>({tagline})</Tagline>}
      {value !== undefined && value !== null && (
        <AnnualRentIcon
          isInCell
          point={{ key: cellKey, isConfirmedTotalRent }}
        />
      )}
      {hasMatchingRecords?.matches?.length > 1 && (
        <Tooltip tooltipId={`MatchingIcon_${cellKey}`} button={matchingIcon}>
          {copy}
        </Tooltip>
      )}
    </StyledHeadingCell>
  );
};
export default HeadingCell;
