import {
  AddEpcAction,
  AddEpcActionTypes,
  AddEpcFailAction,
  AddEpcSuccessAction,
} from 'connected/EpcCertificatesPanel/AddEpc/types';
import {
  DeleteEpcAction,
  DeleteEpcActionTypes,
  DeleteEpcFailAction,
  DeleteEpcSuccessAction,
} from 'connected/EpcCertificatesPanel/DeleteEpc/types';
import { GlobalApiFailError } from './types';

// ADD EPC
export const addEpc = (epcIds: string[], buildingId: string): AddEpcAction => ({
  type: AddEpcActionTypes.ADD_EPC,
  payload: { epcIds, buildingId },
});

export const addEpcSuccess = (
  epcIds: string[],
  buildingId: string,
): AddEpcSuccessAction => ({
  type: AddEpcActionTypes.ADD_EPC_SUCCESS,
  payload: { epcIds, buildingId },
});

export const addEpcFail = (payload: GlobalApiFailError): AddEpcFailAction => ({
  type: AddEpcActionTypes.ADD_EPC_FAIL,
  payload,
});

// DELETE EPC
export const deleteEpc = (
  epcId: string,
  buildingId: string,
): DeleteEpcAction => ({
  type: DeleteEpcActionTypes.DELETE_EPC,
  payload: { epcId, buildingId },
});

export const deleteEpcSuccess = (
  epcId: string,
  buildingId: string,
): DeleteEpcSuccessAction => ({
  type: DeleteEpcActionTypes.DELETE_EPC_SUCCESS,
  payload: { epcId, buildingId },
});

export const deleteEpcFail = (
  payload: GlobalApiFailError,
): DeleteEpcFailAction => ({
  type: DeleteEpcActionTypes.DELETE_EPC_FAIL,
  payload,
});
