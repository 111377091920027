import { AuthenticatedRequestObservable } from 'apis/request';
import {
  DeleteEpcActionTypes,
  DeleteEpcAction,
} from 'connected/EpcCertificatesPanel/DeleteEpc/types';
import { endpoints } from 'globalConstants';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { deleteEpcSuccess, deleteEpcFail } from 'store/actions/epcActions';

type DeleteEpcEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

const deleteEpcEpic: Epic = (
  action$,
  state$,
  { authRequest }: DeleteEpcEpicDependencies,
) =>
  action$.pipe(
    ofType(DeleteEpcActionTypes.DELETE_EPC),
    mergeMap((action: DeleteEpcAction) => {
      const url = endpoints.epc;

      return authRequest(state$, {
        url,
        method: 'DELETE',
        body: action.payload,
      })().pipe(
        map((ajaxResponse) => {
          if (ajaxResponse.status > 200) {
            throw new Error(`error loading ${url}`);
          }
          return deleteEpcSuccess(
            action.payload.epcId,
            action.payload.buildingId,
          );
        }),
      );
    }),
    catchError((error) => of(deleteEpcFail(error))),
  );

export default deleteEpcEpic;
