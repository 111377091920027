import Checkbox from 'components/Forms/Inputs/Checkbox/Checkbox';
import React from 'react';
import { ActionCellWrapper } from '../TableStyled';

interface CheckboxCellProps {
  value: any;
  subValue: any;
  isIconCell: boolean;
  onChange?: (checked: boolean) => void;
}

export const CheckboxCell: React.FC<CheckboxCellProps> = ({
  value,
  subValue,
  isIconCell,
  onChange,
}) => {
  const handleOnChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => onChange?.(checked);

  return (
    <ActionCellWrapper isIconCell={isIconCell}>
      <Checkbox
        label=""
        name={value}
        value={subValue}
        isChecked={subValue}
        onChange={handleOnChange}
        onKeyPress={() => {}}
      />
    </ActionCellWrapper>
  );
};
export default CheckboxCell;
