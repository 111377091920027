/* eslint-disable react/no-array-index-key */
import React from 'react';
import InlineActionMenu from 'components/ActionsMenu/InlineActionMenu';
import { Actions } from '../../types';
import Action from './Action';
import { ActionCellWrapper } from '../../TableStyled';

interface ActionCellProps {
  actions: Actions[];
  cellKey?: string;
}

export const ActionCell: React.FC<ActionCellProps> = ({ actions, cellKey }) => (
  <ActionCellWrapper>
    <InlineActionMenu>
      {actions.map((action: Actions, i: number) => (
        <Action
          key={`${action.type}_${cellKey}_${i}`}
          handleAction={action.handleAction}
          link={action.link}
          type={action.type}
          isDisabled={action.isDisabled}
          showTooltipIfDisabled={action.showTooltipIfDisabled}
          isAdminOnly={action.isAdminOnly}
          modalConfig={action.modalConfig}
        />
      ))}
    </InlineActionMenu>
  </ActionCellWrapper>
);

export default ActionCell;
