import React from 'react';
import { EpcBand } from '../TableStyled';

type EpcBandConfig = {
  borderColour: string;
  backgroundColour: string;
};

const EpcBandColourMap = new Map<string, EpcBandConfig>([
  ['A+', { borderColour: '#00C781', backgroundColour: '#ffffff' }],
  ['A', { borderColour: '#00C781', backgroundColour: '#00C781' }],
  ['B', { borderColour: '#19B459', backgroundColour: '#19B459' }],
  ['C', { borderColour: '#8DCE46', backgroundColour: '#8DCE46' }],
  ['D', { borderColour: '#FFD500', backgroundColour: '#FFD500' }],
  ['E', { borderColour: '#FCAA65', backgroundColour: '#FCAA65' }],
  ['F', { borderColour: '#EF8023', backgroundColour: '#EF8023' }],
  ['G', { borderColour: '#E9153B', backgroundColour: '#E9153B' }],
]);

export const mapEpcRatingCell = (
  epcRating?: number,
  epcRatingBand?: string,
) => {
  if (!epcRatingBand || !epcRating) return null;

  const epcBandConfig = EpcBandColourMap.get(epcRatingBand);

  if (!epcBandConfig) return null;

  return (
    <EpcBand
      borderColour={epcBandConfig.borderColour}
      backgroundColour={epcBandConfig.backgroundColour}
    >
      {epcRating}&nbsp;|&nbsp;{epcRatingBand}
    </EpcBand>
  );
};
