import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { InlineModalActionButton } from 'components/CallToActions/Button/Button';
import { globalModalShow } from 'store/actions/globalModalActions';
import { Actions } from '../../types';
import { ActionPlaceholder } from '../../TableStyled';

const Action: FC<Actions> = ({ modalConfig }) => {
  const dispatch = useDispatch();

  if (modalConfig) {
    const handleOnClick = () => {
      return dispatch(
        globalModalShow(modalConfig.modalId, true, null, modalConfig.recordId),
      );
    };
    return (
      <InlineModalActionButton onClick={handleOnClick}>
        {modalConfig.triggerButton}
      </InlineModalActionButton>
    );
  }
  return <ActionPlaceholder />;
};

export default Action;
