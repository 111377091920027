import { TableCellType } from 'components/Table/types';
import React from 'react';
import { StyledLink } from 'components/Table/TableStyled';
import { HeadingCell } from './HeadingCell';

interface HeadingThumbnailTooltipLinkCellProps {
  imageTooltipUrl: string;
  value: TableCellType;
}

export const HeadingThumbnailTooltipLinkCell: React.FC<HeadingThumbnailTooltipLinkCellProps> = ({
  imageTooltipUrl,
  value,
}) => {
  return imageTooltipUrl && value.linkTo ? (
    <StyledLink
      to={value.linkTo}
      data-for="heading_thumbnail_tooltip_link_cell"
      data-tip={imageTooltipUrl}
    >
      <HeadingCell {...value} />
    </StyledLink>
  ) : null;
};
export default HeadingThumbnailTooltipLinkCell;
