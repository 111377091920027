import React, { useMemo } from 'react';
import { PanelTheme } from 'components/PanelWrapper/types';
import SortPaginationTable from 'components/Table/components/SortPaginationTable';
import { TableRowProps } from 'components/Table/types';
import { mapAthenaResultsToTableFormattedData } from './helpers';
import { EpcSelectAthenaResult } from '../types';
import { EPC_RESULTS_TABLE_HEADERS } from './constants';

interface EpcSelectResultsTableProps {
  results: EpcSelectAthenaResult[];
  selectedIds: string[];
  onCheckboxChange(id: string, checked: boolean): void;
}

const EpcSelectResultsTable: React.FC<EpcSelectResultsTableProps> = ({
  results,
  onCheckboxChange,
  selectedIds,
}: EpcSelectResultsTableProps) => {
  const formattedResults = useMemo(
    () =>
      results.map((r) =>
        mapAthenaResultsToTableFormattedData(r, selectedIds, onCheckboxChange),
      ),
    [results, selectedIds, onCheckboxChange],
  );
  const rows = useMemo(() => {
    return formattedResults?.reduce(
      (accum: TableRowProps[], result: TableRowProps) => {
        const sortedRows = EPC_RESULTS_TABLE_HEADERS?.reduce(
          (acc: any, header: any) => {
            return {
              ...acc,
              [header.key]: result[header.key],
              id: result.id,
              entity: result.entity,
            };
          },
          {},
        );
        return [...accum, sortedRows];
      },
      [],
    );
  }, [formattedResults]);

  return (
    <SortPaginationTable
      columns={EPC_RESULTS_TABLE_HEADERS.length + 2}
      tableId="epcResultsTable"
      rows={rows}
      headers={EPC_RESULTS_TABLE_HEADERS}
      useTheme={PanelTheme.PINK}
      tableUseContext="DETAILS"
      usePagination={false}
    />
  );
};

export default EpcSelectResultsTable;
