/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action } from 'redux';
import { ofType, Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap,
  map,
  withLatestFrom,
  catchError,
  retryWhen,
  takeWhile,
  delay,
} from 'rxjs/operators';

import { endpoints } from 'globalConstants';
import { AuthenticatedRequestObservable } from 'apis/request';
import { AddEpcActionTypes } from 'connected/EpcCertificatesPanel/AddEpc/types';
import {
  getPollingEpcBuildingId,
  getPollingEpcLmkKeys,
  getPollingEpcOperationType,
} from 'store/selectors/pollingEpc';
import {
  PollingEpcActionTypes,
  pollingEpcFail,
  pollingEpcSuccess,
  pollingEpcUnknown,
} from 'store/actions/pollingEpcActions';
import { DeleteEpcActionTypes } from 'connected/EpcCertificatesPanel/DeleteEpc/types';
import { PollingEpcType } from 'store/reducers/pollingEpcReducer';

type PollingEpcEpicDependencies = {
  authRequest: AuthenticatedRequestObservable;
};

interface GetEpcDto {
  id: string;
  lmkKey: string;
}

const pollingEpcEpic: Epic = (
  action$,
  state$,
  dependencies: PollingEpcEpicDependencies,
) => {
  return action$.pipe(
    ofType<Action, Action, any>(
      AddEpcActionTypes.ADD_EPC_SUCCESS,
      DeleteEpcActionTypes.DELETE_EPC_SUCCESS,
    ),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const buildingId = getPollingEpcBuildingId(state);
      const epcIds = getPollingEpcLmkKeys(state);
      const type = getPollingEpcOperationType(state);
      const url = `${endpoints.epc}?buildingId=${encodeURIComponent(
        buildingId,
      )}`;

      let retryAttempts = 0;

      const isFinished = (currentLmkKeys: string[]): boolean => {
        switch (type) {
          case PollingEpcType.ADD_EPC:
            return epcIds.every((id) => currentLmkKeys.includes(id));

          case PollingEpcType.DELETE_EPC:
            return epcIds.every((id) => !currentLmkKeys.includes(id));

          default:
            return false;
        }
      };

      return dependencies
        .authRequest(state$, {
          method: 'GET',
          url,
        })()
        .pipe(
          map((results) => {
            const currentLmkKeys: string[] = results.response.map(
              (r: GetEpcDto) => r.lmkKey,
            );
            if (isFinished(currentLmkKeys)) {
              return pollingEpcSuccess();
            }

            throw new Error(PollingEpcActionTypes.POLLING_RETRY);
          }),
          retryWhen((errors) =>
            errors.pipe(
              takeWhile((error) => {
                if (
                  error.message !== PollingEpcActionTypes.POLLING_RETRY ||
                  retryAttempts === 5
                ) {
                  throw error;
                }

                retryAttempts += 1;
                return true;
              }),
              delay(1000),
            ),
          ),
          catchError((error) => {
            retryAttempts = 0;

            return error.message === PollingEpcActionTypes.POLLING_RETRY
              ? of(pollingEpcUnknown())
              : of(pollingEpcFail());
          }),
        );
    }),
  );
};

export default pollingEpcEpic;
