import { RootState } from 'store/rootReducer';

export const getIsPollingEpc = (state: RootState) => state.pollingEpc.polling;

export const getPollingEpcLmkKeys = (state: RootState) =>
  state.pollingEpc.epcIds;

export const getPollingEpcBuildingId = (state: RootState) =>
  state.pollingEpc.buildingId;

export const getPollingEpcStatus = (state: RootState) =>
  state.pollingEpc.success;

export const getPollingEpcOperationType = (state: RootState) =>
  state.pollingEpc.type;
