import React from 'react';
import { AccessibleText } from 'styled/Global';
import { TABLE_COPY } from '../constants';
import { SortAscIcon, SortDescIcon, SortIconStyled } from '../TableStyled';
import { SortDirections, SortIconProps } from '../types';

export const SortIcon = ({
  title,
  direction,
  sortConfig,
  onClick,
}: SortIconProps) => {
  const isSortButtonDisabled = (key: string, dir: string) =>
    (sortConfig && sortConfig.key === key && sortConfig.direction === dir) ||
    false;

  return (
    <SortIconStyled
      role="button"
      onClick={onClick}
      disabled={isSortButtonDisabled(title, SortDirections[direction])}
    >
      {direction === 'ASC' ? <SortAscIcon /> : <SortDescIcon />}
      <AccessibleText>
        {direction === 'ASC'
          ? TABLE_COPY.buttons.sort.asc
          : TABLE_COPY.buttons.sort.desc}
      </AccessibleText>
    </SortIconStyled>
  );
};
