/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

import { globalContent } from 'globalConstants';
import {
  StyledUnderlinedButton,
  StyledEmptyButton,
  StyledExternal,
} from 'components/PanelGroup/PanelGroupStyled';
import { TableCellStyled } from '../TableStyled';
import { LinkTableCellProps } from '../types';

const getLabel = (isExternal: boolean, link: string, label?: string) => {
  if (label && isExternal) {
    return (
      <StyledExternal rel="noopener noreferrer" target="_blank" href={link}>
        {label}
      </StyledExternal>
    );
  }

  if (label && !isExternal) {
    return (
      <StyledUnderlinedButton as={Link} to={link} target="_blank">
        {label}
      </StyledUnderlinedButton>
    );
  }

  return (
    <StyledEmptyButton as={Link} to={link} target="_blank">
      {globalContent.addData}
    </StyledEmptyButton>
  );
};

const LinkTableCell: React.FC<LinkTableCellProps> = ({
  link,
  label,
  isExternal = false,
}) => <TableCellStyled>{getLabel(isExternal, link, label)}</TableCellStyled>;

export default LinkTableCell;
