import { GlobalApiFailError } from 'store/actions/types';

export enum DeleteEpcActionTypes {
  DELETE_EPC = 'DELETE_EPC',
  DELETE_EPC_SUCCESS = 'DELETE_EPC_SUCCESS',
  DELETE_EPC_FAIL = 'DELETE_EPC_FAIL',
}

export type DeleteEpcActionPayload = {
  epcId: string;
  buildingId: string;
};

export type DeleteEpcAction = {
  type: DeleteEpcActionTypes.DELETE_EPC;
  payload: DeleteEpcActionPayload;
};

export type DeleteEpcSuccessAction = {
  type: DeleteEpcActionTypes.DELETE_EPC_SUCCESS;
  payload: DeleteEpcActionPayload;
};

export type DeleteEpcFailAction = {
  type: DeleteEpcActionTypes.DELETE_EPC_FAIL;
  payload: GlobalApiFailError;
};

export type DeleteEpcActions =
  | DeleteEpcAction
  | DeleteEpcSuccessAction
  | DeleteEpcFailAction;
