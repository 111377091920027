import React from 'react';
import { Vertical } from 'globalConstants';
import { Actions } from '../../types';
import Action from './Action';
import { ActionCellWrapper } from '../../TableStyled';

interface IconCellProps {
  actions: Actions[];
  isIconCell: boolean;
  vertical: Vertical | undefined;
}

export const IconCell: React.FC<IconCellProps> = ({
  actions,
  isIconCell,
  vertical,
}) => (
  <ActionCellWrapper isIconCell={isIconCell}>
    {actions.map((action: Actions) => (
      <Action
        key={`${action.type}_${action.link}`}
        handleAction={action.handleAction}
        link={action.link}
        type={action.type}
        isDisabled={action.isDisabled}
        showTooltipIfDisabled={action.showTooltipIfDisabled}
        tooltipContent={action?.tooltipContent}
        id={action.id}
        isAdminOnly={action.isAdminOnly}
        vertical={vertical}
      />
    ))}
  </ActionCellWrapper>
);

export default IconCell;
