import React, { useState, useRef, MouseEvent, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

import { globalContent } from 'globalConstants';
import CustomToast from 'components/CustomToast/CustomToast';
import InlineEditForm from 'connected/FormFlow/InlineEditForm';
import { StyledEmptyButton } from 'components/PanelGroup/PanelGroupStyled';
import { PageBlur } from 'components/ConfigureTablePanel/ConfigureTablePanelStyled';
import { FIELD_COMPONENTS, OptionProps } from 'connected/FormFlow/types';
import GlobalModalWrapper from 'connected/GlobalModal/GlobalModalWrapper';
import EditAssociatedCompanies from 'connected/EditModals/EditAssociatedCompanies';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentModalSelector,
  getModalIsOpenSelector,
} from 'store/selectors/globalModalSelectors';
import { globalModalShow } from 'store/actions/globalModalActions';
import { TableCellStyled, EditCellModalWrapper } from '../TableStyled';

const modalRoot = document.getElementById('modal-root') as HTMLElement;

const EditableTableCell: React.FC<any> = ({
  cell,
  rowId,
  entity,
  editInSitu,
  useContext = 'RESULTS',
}) => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getModalIsOpenSelector);
  const currentModal = useSelector(getCurrentModalSelector);
  const toastId = React.useRef<string | number | null>(null);
  const [editIsVisible, setEditIsVisible] = useState(false);
  const leftOffset = useRef(0);
  const topOffset = useRef(0);

  const notify = (label: string) => {
    toastId.current = toast(
      <CustomToast message={`${label} ${globalContent.updating}`} />,
      { containerId: 'results-toast', toastId: `${cell.apiKey}-toast` },
    );
  };

  const onClickAddData = (event: MouseEvent<HTMLButtonElement>) => {
    const element = event.target as HTMLButtonElement;
    const { left, top } = element.getBoundingClientRect();

    leftOffset.current = left;
    topOffset.current = top;

    setTimeout(() => {
      setEditIsVisible(true);
    }, 0);
  };

  const onClickOpenEditModal = () => {
    if (cell.editModal) {
      dispatch(globalModalShow(cell.editModal.name, true));
    }
  };

  const onCloseEditForm = () => {
    setEditIsVisible(false);
  };

  const options: OptionProps[] | undefined = useMemo(
    () =>
      cell.valueLabels
        ? Array.from(cell.valueLabels as Map<string | boolean, string>).map(
            (option) => {
              return {
                id: `${option[0]}`,
                value: `${option[0]}`,
                label: `${option[1]}`,
              };
            },
          )
        : undefined,
    [cell.valueLabels],
  );

  const editComponentProps = {
    label: cell.keyLabel,
    name: cell.key,
    options,
    handleClose: onCloseEditForm,
    componentType: cell.componentType,
    validation: cell.validation,
    apiKey: cell.apiKey,
    entityName: cell.entityName || entity,
    entityId: cell.entityId || rowId,
    metaUseContext: useContext,
    decimals: cell.decimals,
  };

  // used for building details sales buyers/sellers & leases landlord/tenant in-line add feature
  if (cell.componentType === FIELD_COMPONENTS.COMPANY_SELECT) {
    return (
      <TableCellStyled>
        {modalIsOpen && currentModal === cell.editModal?.name && (
          <GlobalModalWrapper
            modalId={cell.editModal.name}
            isClickableOutsideToClose={false}
            lightTheme
            size="fullsize"
          >
            <EditAssociatedCompanies
              modalName={cell.editModal.name}
              recordId={rowId}
            />
          </GlobalModalWrapper>
        )}
        <StyledEmptyButton onClick={onClickOpenEditModal}>
          {globalContent.addData}
        </StyledEmptyButton>
      </TableCellStyled>
    );
  }

  return (
    <TableCellStyled>
      {editIsVisible &&
        (editInSitu ? (
          <InlineEditForm {...editComponentProps} />
        ) : (
          ReactDOM.createPortal(
            <>
              <EditCellModalWrapper
                top={topOffset.current}
                left={leftOffset.current}
              >
                <InlineEditForm {...editComponentProps} onSave={notify} />
              </EditCellModalWrapper>
              <PageBlur height="100vh" />
            </>,
            modalRoot,
          )
        ))}
      <StyledEmptyButton onClick={onClickAddData}>
        {globalContent.addData}
      </StyledEmptyButton>
    </TableCellStyled>
  );
};

export default EditableTableCell;
